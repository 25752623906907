import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { LoadingComponent } from '@scandium-oy/ngx-scandium';
import { shareReplay, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { NoResultsComponent } from 'src/app/components/no-results/no-results.component';
import { IScheduleUser } from 'src/app/models/site.model';
import { RoomTicket } from 'src/app/models/ticket.model';
import { TranslateTicketPipe } from 'src/app/pipes/ticket-translate/ticket-translate.pipe';
import { TimestampPipe } from 'src/app/pipes/timestamp/timestamp.pipe';
import { AreasService } from 'src/app/services/areas.service';

@Component({
  selector: 'app-user-tickets-schedule-dialog',
  templateUrl: './user-tickets.dialog.html',
  styleUrls: ['./user-tickets.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainDialogComponent,
    NoResultsComponent,
    TranslateTicketPipe,
    LoadingComponent,
    TimestampPipe,
  ],
})
export class UserTicketsTodayDialogComponent {
  private areasService = inject(AreasService);
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);

  private date = this.navParams.get<Date>('date');

  loading = signal(true);

  user = this.navParams.get<(IScheduleUser & { name?: string })>('user');

  tickets$ = this.areasService.getUserTicketsByDate(this.date, this.user.guid, this.navParams.get('site')).pipe(
    tap(() => this.loading.set(false)),
    shareReplay(1),
  );

  onTicket(roomTicket: RoomTicket) {
    this.areasService.openModal(roomTicket.parent, roomTicket);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
