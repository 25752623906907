<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ user?.displayName ?? user?.name }}
  </div>
  @if (loading()) {
    <app-loading></app-loading>
  }
  @for (ticket of tickets$ | async; track $index) {
    <ion-item lines="none"
      button="true"
      (click)="onTicket(ticket)">
      {{ ticket.name | translateTicket | async }}
      <div>
        @if (ticket.schedule) {
          <div class="times">
            ({{ ticket.schedule.start | timestamp:'HH:mm' }}&nbsp;-&nbsp;
        {{ ticket.schedule.end | timestamp:'HH:mm' }})
          </div>
          }
          <ion-note>
            {{ ticket.roomName }}
          </ion-note>
      </div>
      <span slot="end">
        @if (ticket.hours) {
          <ion-badge>
            {{ ticket.hours }}h
          </ion-badge>
        }
      </span>
    </ion-item>
    } @empty {
      <app-no-results></app-no-results>
    }
</app-main-dialog>